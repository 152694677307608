<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-copy"/> {{caption}}
        <div class="card-header-actions">
          <CButton :color="filters ? 'dark' : 'warning'" size="sm" @click="toggleFilters" style="height: 26px; padding: 2px 5px;"><CIcon name="cil-filter"/></CButton>
          <CButton color="success" size="sm" @click="openLoadXmlModal" v-if="has_access">Cargar XML</CButton>
          <CButton color="info" size="sm" @click="initInvoice" v-if="has_access">Nueva Factura</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="12">
          <CRow v-if="filters">
              <CCol md="4">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Cliente:</label>
                  <CSelect
                    :value.sync="customerSelect" 
                    :options="customersOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Fecha:</label>
                  <v-date-picker                    
                    v-model="range"    
                    class="date-picker"
                    locale="es-MX" size="sm"          
                    mode="range"
                  />        
                </div>
              </CCol>
              <CCol md="3">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Método de Pago:</label>
                   <CSelect
                    :value.sync="paymentMethodSelect" 
                    :options="paymentMethodsOptions"
                    @change="changeFilters"
                  />        
                </div>
              </CCol>              
              <CCol md="2">
                <div role="group" class="form-group" style="margin-top: 25px">
                  <CButton style="margin-right:10px" color="success" size="sm"  @click="downloadExcel()">Exportar</CButton>
                  <CButton color="dark" size="sm"  @click="downloadZip()">PDFs y XML</CButton>
                </div>
              </CCol>
                  
          </CRow>     

          <ul class="nav nav-tabs justify-content-begin">
            <li @click="changeTab('active')" class="nav-item">
              <a class="nav-link" :class="tab == 'active' ? 'active' : ''" aria-current="page">Vigentes <span v-show="totals.active >= 0">({{totals.active}})</span></a>
            </li>
            <li @click="changeTab('pending')" class="nav-item">
              <a class="nav-link" :class="tab == 'pending' ? 'active' : ''">Pendientes de Pago <span v-show="totals.pending >= 0">({{totals.pending}})</span></a>
            </li>
            <li @click="changeTab('paid')" class="nav-item">
              <a class="nav-link" :class="tab == 'paid' ? 'active' : ''">Pagadas <span v-show="totals.paid >= 0">({{totals.paid}})</span></a>
            </li>
            <li @click="changeTab('canceled')" class="nav-item">
              <a class="nav-link" :class="tab == 'canceled' ? 'active' : ''">Canceladas <span v-show="totals.canceled >= 0">({{totals.canceled}})</span></a>
            </li>
          </ul>
 
        </CCol>
      </CRow>
      <br>
      <CRow>
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 30 : 10"
            :dark="dark"
            pagination
            class="listing"
          >
            <template #date="{item}"><td class="w150 text-center">{{ item.date | date }}</td></template>
            <template #folio="{item}"><td class="w150 text-center">{{ item.serial+item.folio }}</td></template>
            <template #subtotal="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.subtotal | currency }}</td></template>
            <template #discounts="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.discounts | currency }}</td></template>
            <template #transfered_taxes="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.transfered_taxes | currency }}</td></template>
            <template #withheld_taxes="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.withheld_taxes | currency }}</td></template>
            <template #total="{item}"><td class="w150 text-right"><span style="float: left"><span v-if="item.currency != 'MXN'" style="font-size: 12px;">{{item.currency}}</span> $</span>{{ item.total | currency }}</td></template>
            <template #customer_name="{item}"><td class="text-lefy"><span style="font-weight: bold;">{{item.receptor}}</span><br>{{item.customer_name}}</td></template>
            <template #actions="{item}">
              <td style="padding: 0px; border: 6px !important!" class="table_actions w150 text-center">
                <table style="padding: 0px; margin: auto; ">
                 <tr>
                  <td style="padding: 0px; border: 0px !important;">
                    <CButton color="info" size="sm" @click="viewInvoice(item)">Ver Factura</CButton>
                  </td>
                  <td style="padding: 0px; border: 0px !important;">
                    <CDropdown color="info" togglerText="" size="sm">
                      <CDropdownItem href="#" @click="viewInvoice(item)">Ver Factura</CDropdownItem>
                      <CDropdownDivider/>
                      <CDropdownItem href="#" @click="downloadXML(item.id)">Descargar XML</CDropdownItem>
                      <CDropdownItem href="#" @click="downloadPDF(item.id)">Descargar PDF</CDropdownItem>
                      <CDropdownDivider/>
                      <CDropdownItem href="#" @click="cloneInvoice(item)">Clonar Factura</CDropdownItem>
                      <CDropdownItem href="#" @click="paidInvoice(item)" v-if="item.is_paid == 0 && item.canceled == 0">Pagar Factura</CDropdownItem>
                    </CDropdown>
                  </td>
                  </tr>
                </table>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
    <PaymentModal ref="paymentModal" @store="refreshInvoices"></PaymentModal>
    <LoadXmlModal ref="loadXmlModal" @store="refreshInvoices"></LoadXmlModal>
  </CCard>
</template>

<script>
import ws from '../../services/invoices';
import customers_ws from '../../services/customers';
import general_ws from '../../services/general';
import store from '../../store'
import PaymentModal from '../payments/modals/PaymentModal.vue';
import LoadXmlModal from './modals/LoadXmlModal.vue';
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

moment.tz.setDefault('America/Mexico_city');

export default {
  name: 'InvoicesTable',
  components: { LoadXmlModal,PaymentModal, 'v-date-picker': DatePicker },
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean, 
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [ 
        {key: 'date', label: 'Fecha'},
        {key: 'folio', label: 'Folio'},
        {key: 'customer_name', label: 'Cliente'},
        {key: 'subtotal', label: 'Subtotal'},
        {key: 'discounts', label: 'Descuentos'},
        {key: 'transfered_taxes', label: 'Trasladados'},
        {key: 'withheld_taxes', label: 'Retenidos'},
        {key: 'total', label: 'Total'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      toasts: [],
      tab: 'pending',
      totals: {
        active: -1,
        pending: -1,
        paid: -1,
        canceled: -1
      },
      filters: false,
      payment_methods: [],
      paymentMethodsOptions: [],
      paymentMethodSelect:"",
      customers: [],
      customerSelect: "",
      customersOptions: [],
      range: {
        start: new moment().startOf('month').toDate(),
        end: new moment().endOf("month").toDate(),
      },
    }
  },
  mounted: async function() {
    this.loading();

    this.getInitialTotals();

    let filters = this.getFilters();

    await this.getTotals(filters);  

    let response1 = await customers_ws.get(); 

    if(response1.type == "success"){
      this.customers = response1.data;      
      this.customersOptions = await this.$parseSelectOptionsOrdered(this.customers, "id", "rfc:name",[{"label": "Todos","value":""},{"label": "XAXX010101000 - Publico en General","value":0}]);
    }

    let response2 = await general_ws.getPaymentMethods(); 

    if(response2.type == "success"){
      this.payment_methods = response2.data;
      this.paymentMethodsOptions = await this.$parseSelectOptionsOrdered(this.payment_methods, "code", "code:name",[{"label": "Todos","value":""}]);
    }

    this.loaded();
  },
  methods: {
    changeTab (tab) {
      if(this.tab != tab){
        this.tab = tab;
        let filters = this.getFilters();
        this.$emit("refresh", tab,filters );
      }
    },
    toggleFilters () {
      this.filters = !this.filters;
    },
    async openLoadXmlModal () {
      this.$refs.loadXmlModal.openModal();
    },
    async getInitialTotals(){
      let filters = {
        date : [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")],
        customer_id : this.customerSelect,
        payment_method : this.paymentMethodSelect,
      };

      let response = await ws.getTotals(filters);

      if(response.type == "success"){
        if(response.data.active < 100){
          this.range = {
            start: new moment().subtract(3, 'months').startOf('month').toDate(),
            end: new moment().endOf("month").toDate(),
          }
        }
      }
    },
    async getTotals(filters){
      let response = await ws.getTotals(filters);
      if(response.type == "success"){
        this.totals.active = response.data.active;
        this.totals.pending = response.data.pending_paid;
        this.totals.paid = response.data.paid;
        this.totals.canceled = response.data.canceled + response.data.process_canceled;
      }
    },
    async changeFilters () {
      let filters = this.getFilters();
      await this.getTotals(filters)
      this.$emit("refresh", this.tab,filters );
    },
    initInvoice () {
      window.location.href = "/#/invoices/draft";
    },
    cloneInvoice (item) {
      window.location.href = "/#/invoices/"+item.id+"/clone";
    },
    viewInvoice (item) {
      window.location.href = "/#/invoices/"+item.id+"/view";
    },
    paidInvoice (item) {
      this.$refs.paymentModal.storeModal(item);
    },
    getFilters(){
      let filters = {
        date : [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")],
        customer_id : this.customerSelect,
        payment_method : this.paymentMethodSelect,
      };

      return filters;
    },
    async refreshInvoices(data){
      this.loading();

      window.location.href = "/#/payments/"+data.id+"/view";

      this.loaded();
    },
    async downloadXML(id){
      ws.downloadXML(id);
    },
    async downloadPDF(id){
      ws.downloadPDF(id);
    },
    async downloadExcel(){
      this.loading();

      let filters = this.getFilters();
      filters.type = this.tab;
      ws.downloadExcel(filters);
      
      this.loaded();
    },
    async downloadZip(){
      this.loading();

      let filters = this.getFilters();
      filters.type = this.tab;
      ws.downloadZip(filters);

      this.loaded();
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  },
  computed: {
    has_access () {
      return store.state.stamps <= 0 || store.state.deadline_days <= 0 ? false : true;
    },
  }
}
</script>
